@use '../../styles/vars' as *;

.intro {
    background-color: #4896f6;
    background-image: url(./img/intro-bg.webp);
    background-size: cover;
    background-position: top center;
    overflow: hidden;
    position: relative;
}

.intro__inner {
    min-height: 100vh;
    padding: 160px 0;
    position: relative;
    z-index: 2;
}

.intro__images {
    display: flex;
    column-gap: 8px;
    margin-bottom: 32px;

    img {
        width: 120px;
    }
}
