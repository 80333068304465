@use '../../styles/vars' as *;

// wrapper
.timeline {
    padding-left: 44px;
    position: relative;

    &::before {
        content: '';
        width: 2px;
        height: 100%;
        background-color: #ccc;
        position: absolute;
        top: 0px;
        left: 11px;
        z-index: 1;
    }
}

// single item
.timeline__item {
    position: relative;

    // bullet
    &::before {
        content: '';
        width: 24px;
        height: 24px;
        background-color: #ccc;
        border: 4px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        left: -44px;
        z-index: 2;
    }

    &:first-child {
        &::before {
            top: -2px;
        }
    }

    &:last-child {
        &::after {
            content: '';
            width: 24px;
            height: 24px;
            background-color: #ccc;
            border: 4px solid #fff;
            border-radius: 50%;
            position: absolute;
            bottom: -8px;
            left: -44px;
            z-index: 2;
        }
    }

    + .timeline__item {
        margin-top: 40px;
    }
}

// title + link
.timeline__title {
    display: inline-block;
    margin-bottom: 16px;
    color: #6b6a6a;
    font-size: 18px;
    font-weight: 300;

    &:hover {
        text-decoration: underline;
    }
}
