@use '../../styles/vars' as *;

.accordion__item {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    position: relative;

    &:first-child {
        border-top-width: 2px;
    }

    &:last-child {
        border-bottom-width: 2px;
    }

    // active styles
    &.accordion__item--active {
        // icon
        .accordion__title {
            &::before {
                transform: scaleY(0);
            }

            // active state
            &:hover,
            &:focus {
                // icon
                &::before {
                    background-color: #fff;
                    transform: translate3d(-20px, -50%, 0) scaleY(0);
                }
            }
        }
    }
}

// title/button
.accordion__title {
    width: 100%;
    max-width: none;
    padding: 32px 60px 32px 0;
    color: #000;
    background-color: #fff;
    border: none;
    font-size: 24px;
    text-align: left;
    transition: $transition;
    transition-property: color, background-color;
    cursor: pointer;
    position: relative;

    // title text
    & > span {
        display: inline-block;
        transition: inherit;
        transition-property: transform;
    }

    // icon
    &::before,
    &::after {
        content: '';
        background-color: #000;
        transition: inherit;
        transition-property: background-color, transform;
        position: absolute;
        z-index: 1;
    }

    // icon (vertical line)
    &::before {
        width: 4px;
        height: 24px;
        transform: translateY(-50%);
        top: 50%;
        right: 10px;
    }

    // icon (horizontal line)
    &::after {
        width: 24px;
        height: 4px;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
    }

    // active state
    &:hover,
    &:focus {
        color: #fff;
        background-color: #000;

        // title text
        & > span {
            transform: translate3d(20px, 0, 0);
        }

        // icon
        &::before,
        &::after {
            background-color: #fff;
            transform: translate3d(-20px, -50%, 0);
        }
    }
}

// wrapper for the content
.accordion__content-wrapper {
    height: 0;
    transition: 0.35s ease-out;
    overflow: hidden;
    will-change: height;
}

// content
.accordion__content {
    padding: 32px 0;
}

// screen adaptation
@media (max-width: $tablet) {
    // small title
    .accordion__title {
        font-size: 22px;
    }
}

// phone
@media (max-width: $phone) {
    .accordion__title {
        font-size: 20px;
    }
}
